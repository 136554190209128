import type { ApiKeys } from '@/types/api'
import type { ReCaptchaV2 } from '~/types/recaptcha'

type Grecpatcha = {
  grecaptcha: ReCaptchaV2
}
declare global {
  interface Window extends Grecpatcha {}
}

export const useRecaptcha = () => {
  const passThrowRecaptchaV3 = useState('passThrowRecaptchaV3', () => true)
  const recaptchaLoaded = useState('recaptchaLoaded', () => false)
  const recaptchaV2ApiKey = useState<string | null>('recaptchaV2ApiKey', () => null)
  const recaptchaV3ApiKey = useState<string | null>('recaptchaV3ApiKey', () => null)
  const recaptchaV2Key = useState<string | undefined>('recaptchaV2Key')
  const apiKeysPromise = useState<Promise<void | ApiKeys> | null>('api-keys', () => null)
  const { public: config } = useRuntimeConfig()

  const executeV3 = async (action: string) => {
    if (!recaptchaLoaded.value) {
      throw Error('Recaptcha is not yet loaded')
    }
    return new Promise((rosolve, reject) => {
      try {
        window.grecaptcha.ready(() => {
          if (recaptchaV3ApiKey.value) {
            window.grecaptcha.execute(recaptchaV3ApiKey.value, { action }).then((token: string) => {
              rosolve(token)
            })
          } else {
            reject('No recaptcha Api key provided')
          }
        })
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  watch(recaptchaV2Key, (newValue) => {
    if (newValue) {
      passThrowRecaptchaV3.value = true
    }
  })

  const loadRecaptchaScript = async (v3ApiKey: string) => {
    const { load, onLoaded } = useScript(
      {
        src: `https://www.google.com/recaptcha/api.js?render=${v3ApiKey}`,
        referrerpolicy: false,
        crossorigin: false,
      },
      { trigger: 'manual' }
    )
    onLoaded(() => {
      recaptchaLoaded.value = true
    })
    await load()
  }

  const getRecaptchaHeaders = async (recaptchaActionName: string) => {
    if (!apiKeysPromise.value && (!recaptchaV3ApiKey.value || !!recaptchaV2ApiKey.value)) {
      const getRecaptchaApi3Key = new Promise((resolve) => {
        apiKeysPromise.value = $fetch<{ data: ApiKeys }>(
          `${config.apiBaseUrl}/public/settings/api-keys`
        ).then(({ data }) => {
          recaptchaV2ApiKey.value = data.recaptcha_v2_site_key.value
          recaptchaV3ApiKey.value = data.recaptcha_site_key.value
          resolve(data)
        })
      })
      await getRecaptchaApi3Key
    }
    if (recaptchaV3ApiKey.value) {
      await loadRecaptchaScript(recaptchaV3ApiKey.value)
    } else {
      if (!recaptchaV3ApiKey.value) {
        console.warn('No Google Recaptcha v3 site key provided')
      }
      if (!recaptchaV2ApiKey.value) {
        console.warn('No Google Recaptcha v2 site key provided')
      }
    }
    const recaptchaToken =
      recaptchaV2Key.value ?? ((await executeV3(recaptchaActionName)) as string)
    const recaptchaVersionName = recaptchaV2Key.value
      ? 'X-Google-Recaptcha-V2-Token'
      : 'X-Google-Recaptcha-V3-Token'

    return {
      version: recaptchaVersionName,
      token: recaptchaToken,
    }
  }

  return {
    getRecaptchaHeaders,
    passThrowRecaptchaV3,
    recaptchaV2Key,
    recaptchaLoaded,
  }
}
