export function useLocale() {
  const { $i18n: i18n } = useNuxtApp()
  const loadedLanguages = ref<{ [key: string]: boolean }>({})
  const nuxtApp = useNuxtApp()
  const {
    public: { locales },
  } = useRuntimeConfig()

  const locale = useState<string>('locale', () => nuxtApp.$i18n.locale.value)
  const setLocale = async (newLocale: string) => {
    const shortLocale = newLocale.split('_')[0] as 'cs' | 'en' | 'ru'
    await loadMessages(shortLocale)
    if (shortLocale) {
      await i18n.setLocale(shortLocale)
    }
    locale.value = shortLocale
    useCookie(localeCookieKey, { sameSite: true }).value = newLocale
  }

  const loadMessages = async (lang: 'cs' | 'en' | 'ru') => {
    if (lang && !loadedLanguages.value[lang]) {
      try {
        const messages = await useCustomFetch<{ [key: string]: string }>(
          '/public/translations/generate',
          {
            domain: 'web_client',
            lang: locales[lang],
          }
        )
        if (messages?.data?.value) {
          loadedLanguages.value[lang] = true
          i18n.setLocaleMessage(lang, messages.data.value)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  return {
    locale,
    setLocale,
    loadMessages,
  }
}
