import QueryString from 'qs'
import type { UseFetchOptions } from 'nuxt/app'
import type { StdObject } from '~/types/internal'

export async function useCustomFetch<T>(
  url: string | (() => string),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any = {},
  thumbs: StdObject | null = null,
  options: UseFetchOptions<T> = {},
  fullResponse: boolean = false
) {
  const { auth } = useAuth()
  const { basket } = useBasketService()
  const { public: config } = useRuntimeConfig()
  const { config: userConfig } = useUserConfig()
  const objectQuery: StdObject = { ...query }
  if (thumbs) {
    objectQuery.thumb = thumbs
  }
  const queryString = QueryString.stringify(objectQuery)
  const headers: { [header: string]: string } = {
    Accept: 'application/json',
    'X-Currency': userConfig.value.currency,
    Authorization: `Bearer ${auth.token}`,
  }
  if (basket.value.guid) {
    headers['X-Basket-Id'] = basket.value.guid
  }
  const fetchUrl = `${config.apiBaseUrl}${url}${queryString ? `?${queryString}` : ''}`
  let fullRes = null
  const response = await useFetch(fetchUrl, {
    ...options,
    headers: { ...options.headers, ...headers },
    onResponse({ response }) {
      if (fullResponse) {
        fullRes = response
      }
    },
  })

  if (response.error.value) {
    throw createError({
      statusCode: response.error.value?.statusCode,
      message: response.error.value?.message,
      fatal: true,
    })
  }

  if (fullResponse) {
    return fullRes
  }
  return response
}
