import type { LinkTypes } from '@/types/link'
export const redirect = <T>(type: LinkTypes, data: T, link: string) => {
  const { $localePath } = useNuxtApp()
  const router = useRouter()
  switch (type) {
    case 'anchor':
      return `#${link}`
    case 'article':
      return router.resolve($localePath({ name: 'article-detail', params: { ...data } }))?.path
    case 'article-list':
      return router.resolve($localePath({ name: 'article-list' }))?.path
    case 'external':
      return link
    case 'login':
      return router.resolve($localePath({ name: 'login' }))?.path
    case 'logout':
      return router.resolve($localePath({ name: 'logout' }))?.path
    case 'product':
      return router.resolve($localePath({ name: 'product-detail', params: { ...data } }))?.path
    case 'product-list':
      return router.resolve($localePath({ name: 'product-list' }))?.path
    case 'product-list-color':
      return router.resolve($localePath({ name: 'category-detail', params: { ...data, c: 'c' } }))
        ?.path
    case 'product-list-flower':
      return router.resolve($localePath({ name: 'category-detail', params: { ...data, c: 'f' } }))
        ?.path
    case 'product-list-occasions':
      return router.resolve($localePath({ name: 'category-detail', params: { ...data, c: 'o' } }))
        ?.path
    case 'register':
      return router.resolve($localePath({ name: 'register' }))?.path
    case 'section':
      return
    case 'home':
      return '/'
  }
}
