export const ARTICLE_NOT_FOUND = 'main.not-found'
export const ARTICLE_HOME = 'main.home-page'
export const ARTICLE_ABOUT_US = 'main.about-us'
export const ARTICLE_CONTACTS = 'main.contact'
export const ARTICLE_TERMS = 'main.terms'
export const ARTICLE_COMPLAINT = 'main.complaint'
export const ARTICLE_PRIVACY = 'main.privacy'
export const ARTICLE_SHIPPING = 'main.shipping'
export const ARTICLE_ADDRESS = 'main.address'
export const ARTICLE_ERROR = 'main.error'
export const ARTICLE_DENIED = 'main.denied'
export const ARTICLE_PARTNERS = 'main.partners'

export const ARTICLE_EU = 'bf.transformation'
