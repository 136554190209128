import type { UserConfig } from '~/types/internal'

export function useUserConfig() {
  const langCookieKey = 'user-lang'
  const currencyCookieKey = 'user-currency'
  const config = useState<UserConfig>('user-config', () => {
    return {
      lang: useCookie(langCookieKey, { sameSite: true })?.value || 'cs',
      currency: useCookie(currencyCookieKey, { sameSite: true })?.value || 'CZK',
    }
  })

  const changeCurrency = (currency: string) => {
    config.value.currency = currency
    useCookie(currencyCookieKey, { sameSite: true }).value = currency
  }

  return {
    config,
    changeCurrency,
  }
}
