import type { LinkTypes } from '@/types/link'

export default defineNuxtRouteMiddleware(async (to) => {
  const url = useRequestURL()
  if (!to.name) {
    const { data } = await $customFetch<{
      data: {
        redirect: boolean
        _link: {
          identifier: number
          slug: string
          type: {
            name: string
            value: LinkTypes
          }
          externalLink: string
        }
      }
    }>('/public/urls', {}, undefined, {
      method: 'post',
      body: {
        url: url.href,
        type: 404,
      },
    })
    if (data.redirect) {
      return navigateTo(
        redirect(
          data?._link?.type?.value,
          {
            id: data?._link.identifier,
            slug: data?._link.slug,
          },
          data._link?.externalLink
        )
      )
    }
  }
})
