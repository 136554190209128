export function useGetBrowser(): {
  os: string | null
  browser: string | null
  userAgent: string | null
} {
  const browserList = [
    { name: 'Firefox', value: 'Firefox' },
    { name: 'Opera', value: 'OPR' },
    { name: 'Edge', value: 'Edg' },
    { name: 'Chrome', value: 'Chrome' },
    { name: 'Safari', value: 'Safari' },
  ]
  const sys = [
    { name: 'Android', value: 'Android' },
    { name: 'iPhone', value: 'iPhone' },
    { name: 'Mac', value: 'Mac' },
    { name: 'Linux', value: 'Linux' },
    { name: 'Windows', value: 'Win' },
  ]

  if (process.client) {
    const userDetails = navigator.userAgent
    let browser = null,
      os = null
    for (const i in browserList) {
      if (userDetails.includes(browserList[i].value)) {
        // TODO: Nelze volat i18n metodu
        browser = browserList[i].name || 'Neznámý prohlížeč'
        break
      }
    }
    for (const i in sys) {
      if (userDetails.includes(sys[i].value)) {
        // TODO: Nelze volat i18n metodu
        os = sys[i].name || 'Neznámý OS'
        break
      }
    }

    return {
      os,
      browser,
      userAgent: userDetails,
    }
  }

  return {
    os: null,
    browser: null,
    userAgent: null,
  }
}
