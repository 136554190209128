import type { Auth } from '~/types/auth'

export function useAuth() {
  const auth = useState('auth').value as Auth
  const { passThrowRecaptchaV3, recaptchaV2Key } = useRecaptcha()

  const isLoggedIn = computed(() => !!auth.token)

  const register = async (data: object) => {
    const filteredData = {
      ...data,
      recaptcha: undefined,
    }
    try {
      await $customFetch<{ id: number }>(
        '/account',
        {},
        undefined,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: filteredData,
        },
        'register'
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.response?.status === 403) {
        passThrowRecaptchaV3.value = false
        if (recaptchaV2Key.value) {
          recaptchaV2Key.value = undefined
        }
      }
      throw e
    }
  }

  return {
    auth,
    isLoggedIn,
    register,
  }
}
